<template>
  <v-app>
    <Header />
    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>

        <warning-overlay
          v-model="showWarning"
          message="WARNING: Your user is not associated with a group. Please contact the site administrator."
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import WarningOverlay from "@/components/WarningOverlay";

export default {
  name: "App",
  components: {
    Header,
    WarningOverlay
  },
  data: () => ({
    showWarning: false
  }),
  mounted() {
    setTimeout(() => {
      this.showWarning = this.loggedIn && this.userGroup === undefined;
    }, 1000);
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    userGroup() {
      return this.$store.getters["auth/userGroup"];
    }
  }
};
</script>
