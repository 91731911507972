<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="files"
      hide-default-footer
      :loading="loading"
      loading-text="Processing... Please wait"
      class="elevation-1 mt-2"
    >
      <template v-slot:[`item.size`]="{ item }">
        {{ item.size.toLocaleString(undefined) }} bytes
      </template>
      <template v-slot:[`item.download`]="{ item }">
        <v-icon
          icon
          color="primary"
          class="mr-2"
          @click.stop="download(item.name)"
        >
          mdi-cloud-download
        </v-icon>
      </template>
    </v-data-table>

    <warning-overlay v-model="showWarning" :message="error" />
  </v-container>
</template>

<script>
import WarningOverlay from "@/components/WarningOverlay";

export default {
  name: "ProcessedFiles",
  components: {
    WarningOverlay
  },
  data: () => ({
    showWarning: false,
    headers: [
      { text: "Created At", value: "timestamp" },
      { text: "Filename", value: "name" },
      { text: "Size", value: "size" },
      { text: "Download", value: "download", align: "center", sortable: false }
    ]
  }),
  watch: {
    error: function(err) {
      if (err !== undefined) {
        this.showWarning = true;
      }
    },
    showWarning: function(show) {
      if (!show) {
        this.$store.commit("processed/setError", undefined);
      }
    }
  },
  computed: {
    loading() {
      return this.$store.getters["processed/loading"];
    },
    userGroup() {
      return this.$store.getters["auth/userGroup"];
    },
    files() {
      return this.$store.getters["processed/files"];
    },
    error() {
      return this.$store.getters["processed/error"];
    }
  },
  mounted() {
    this.$store.dispatch("processed/index", this.userGroup);
  },
  methods: {
    download: function(filename) {
      this.$store.dispatch("processed/get", {
        filename: filename,
        client: this.userGroup
      });
    }
  }
};
</script>
