<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
    :width="width"
  >
    <v-card-text>
      <input
        type="file"
        id="fileInput"
        ref="fileInput"
        @change="onFileInputChange"
        style="display: none"
        multiple
      />
      <v-row dense class="d-flex flex-column" align="center" justify="center">
        <v-icon class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-cloud-upload
        </v-icon>
        <p>
          Drag file(s) here or
          <label for="fileInput">
            <span class="link">click</span>
          </label>
          to select
        </p>
      </v-row>
      <v-virtual-scroll
        v-if="files.length > 0"
        :items="files"
        :height="files.length * 56"
        min-height="62"
        max-height="414"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-2 text--secondary">
                  ({{ item.size.toLocaleString(undefined) }} bytes)
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="removeFile(item.name)" icon>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </v-virtual-scroll>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click.stop="submit"
        :disabled="files.length <= 0"
      >
        <v-icon left id="upload-button">mdi-upload</v-icon>
        Upload
      </v-btn>
    </v-card-actions>

    <v-progress-linear v-if="loading" indeterminate />
    <v-overlay :value="loading" opacity="0.4" absolute />
  </v-card>
</template>

<script>
export default {
  name: "FileDrop",
  props: {
    width: { type: String }
  },
  data: () => ({
    dragover: false
  }),
  computed: {
    loading() {
      return this.$store.getters["uploads/loading"];
    },
    files() {
      return this.$store.getters["uploads/files"];
    }
  },
  methods: {
    onDrop(e) {
      this.dragover = false;
      this.addFiles(e.dataTransfer.files);
    },
    onFileInputChange() {
      this.addFiles(this.$refs.fileInput.files);
    },
    addFiles(files) {
      files.forEach(file => {
        this.$store.commit("uploads/addFile", file);
      });
    },
    removeFile(filename) {
      this.$store.commit("uploads/removeFile", filename);
    },
    submit() {
      this.$emit("filesUploaded", this.files);
    }
  }
};
</script>

<style scoped lang="scss">
.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
