import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import UploadFiles from "@/views/UploadFiles";
import ProcessedFiles from "@/views/ProcessedFiles";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Upload Files",
      component: UploadFiles,
      meta: { auth: true }
    },
    {
      path: "/processed_files",
      name: "Processed Files",
      component: ProcessedFiles,
      meta: { auth: true }
    },
    {
      path: "/login",
      beforeEnter: () => {
        store.dispatch("auth/login");
      }
    },
    {
      path: "/login/oauth2/code/cognito",
      beforeEnter: () => {
        store.dispatch("auth/handleLoginCallback");
      }
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        store.dispatch("auth/logout");
        next("/");
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters["auth/loggedIn"]) {
    store.commit("auth/setLoading", true);
    store
      .dispatch("auth/loadUserInfo")
      .then(response => {
        store.commit("auth/setUserInfo", response.data);
        next();
      })
      .catch(() => {
        if (to.path === "/") {
          next();
        } else {
          next("/");
        }
      });
  } else {
    next();
  }
});

export default router;
