import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/Auth";
import uploads from "@/store/modules/Uploads";
import processed from "@/store/modules/Processed";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    uploads,
    processed
  }
});
