import http from "@/lib/Http";

export default {
  namespaced: true,
  state: {
    loading: false,
    files: [],
    error: undefined
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    setError(state, payload) {
      state.error = payload;
    }
  },

  getters: {
    loading: state => state.loading,
    files: state => state.files,
    error: state => state.error
  },

  actions: {
    index({ commit, dispatch }, client) {
      commit("setLoading", true);
      http.get(`/processed?client=${client}`).then(response => {
        if (response.data.complete) {
          commit("setError", undefined);
          if (response.data.result?.error) {
            commit("setError", response.data.result.error);
          }
          commit("setFiles", response.data.files);
          commit("setLoading", false);
        } else {
          commit("setFiles", []);
          setTimeout(() => {
            dispatch("index", client);
          }, 5000);
        }
      });
    },
    get(_, params) {
      http
        .get(`/processed/${params.filename}?client=${params.client}&version=${new Date().valueOf()}`, {
          responseType: "blob"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = params.filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    }
  }
};
