<template>
  <v-app-bar app light>
    <div class="d-flex align-center">
      <v-img
        alt="DR Logo"
        :src="require('../assets/deeproot.png')"
        class="logo-img shrink mr-2"
        contain
        transition="scale-transition"
        width="100"
        @click="goHome"
      />
    </div>

    <v-divider vertical class="pl-4" />

    <span class="pl-6" v-if="loggedIn">
      <h3 class="header-title">{{ $route.name }}</h3>
    </span>

    <v-spacer />

    <v-btn
      v-if="!loading && !loggedIn"
      depressed
      rounded
      plain
      text
      to="/login"
    >
      Sign In
    </v-btn>

    <user-menu v-if="loggedIn" />
  </v-app-bar>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";

export default {
  name: "Header",
  components: {
    UserMenu
  },
  computed: {
    loading: function() {
      return this.$store.getters["auth/loading"];
    },
    loggedIn: function() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  methods: {
    goHome: function() {
      this.$router.push({ path: "/" }).catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.logo-img {
  cursor: pointer;
}
.header-title {
  color: #585858;
}
</style>
