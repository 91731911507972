import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify: new Vuetify(),
  store,
  render: h => h(App)
}).$mount("#app");
