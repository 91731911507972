import { CognitoAuth } from "amazon-cognito-auth-js";
import store from "@/store";
import router from "@/router";

const auth = new CognitoAuth({
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  AppWebDomain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
  TokenScopesArray: ["openid", "email", "profile"],
  RedirectUriSignIn: process.env.VUE_APP_COGNITO_REDIRECT_URI,
  RedirectUriSignOut: process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
  UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID
});

auth.userhandler = {
  onSuccess: function() {
    store.dispatch("auth/loginSuccess");
    router.push("/");
  },
  onFailure: function(err) {
    store.dispatch("auth/loginFailure");
    console.log("authentication failed: ", err);
  }
};

export default auth;
