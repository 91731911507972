<template>
  <v-container fluid>
    <v-overlay :value="value" />
    <v-snackbar
      :value="value"
      color="error"
      :timeout="-1"
      centered
      app
      outlined
    >
      <v-row>
        <v-col cols="1" align="center">
          <v-icon color="error">mdi-alert</v-icon>
        </v-col>
        <v-col>
          {{ message }}
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn text color="error" v-bind="attrs" @click="$emit('input', false)">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "WarningOverlay",
  props: {
    value: { required: true },
    message: { required: true }
  }
};
</script>
