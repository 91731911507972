<template>
  <v-menu
    min-width="200px"
    transition="slide-y-transition"
    bottom
    rounded
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar>
          <v-icon large>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <h3>{{ userInfo.name }}</h3>
          <v-divider class="my-4" />
          <v-row
            v-for="(item, i) in namedRoutes"
            align="center"
            justify="center"
            class="d-flex flex-column"
            :key="i"
          >
            <v-btn depressed rounded plain text :to="item.path">
              {{ item.name }}
            </v-btn>
          </v-row>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "UserMenu",
  computed: {
    userInfo: function() {
      return this.$store.getters["auth/userInfo"];
    },
    namedRoutes: function() {
      return this.$router.options.routes.filter(r => !!r.name);
    }
  }
};
</script>

<style scoped lang="scss"></style>
