import cognito from "@/lib/Cognito";
import http from "@/lib/Http";

export default {
  namespaced: true,
  state: {
    loading: false,
    userInfo: undefined,
    userGroup: undefined
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setUserGroup(state, payload) {
      state.userGroup = payload;
    },
    clearAll(state) {
      state.userInfo = undefined;
      state.userGroup = undefined;
      state.loading = false;
    }
  },

  getters: {
    loading: state => state.loading,
    loggedIn: state => !!state.userInfo,
    userInfo: state => state.userInfo,
    userGroup: state => state.userGroup
  },

  actions: {
    login() {
      cognito.getSession();
    },
    loginSuccess({ dispatch, commit }) {
      dispatch("loadUserInfo").then(response => {
        commit("setUserInfo", response.data);
      });
    },
    loginFailure({ commit }) {
      commit("setUserInfo", undefined);
      commit("setUserGroup", undefined);
    },
    handleLoginCallback() {
      const curUrl = window.location.href;
      cognito.parseCognitoWebResponse(curUrl);
    },
    logout() {
      if (cognito.isUserSignedIn()) {
        cognito.signOut();
      }
    },
    async loadUserInfo({ commit }) {
      const session = cognito.getSignInUserSession();
      const jwtToken = session.getAccessToken().jwtToken;
      const url = `https://${cognito.getAppWebDomain()}/oauth2/userInfo`;

      if (jwtToken) {
        try {
          const parsedToken = JSON.parse(atob(jwtToken.split(".")[1]));
          if (parsedToken["cognito:groups"]) {
            commit("setUserGroup", parsedToken["cognito:groups"][0]);
          }

          return await http.get(url, {
            headers: { Authorization: `Bearer ${jwtToken}` }
          });
        } catch (err) {
          commit("clearAll");
        }
      } else {
        commit("clearAll");
      }
    }
  }
};
