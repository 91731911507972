<template>
  <v-container fluid v-if="loggedIn">
    <v-row class="d-flex flex-column mt-2" align="center" justify="center">
      <file-drop width="70%" @filesUploaded="processUpload($event)" />
    </v-row>

    <warning-overlay v-model="showWarning" :message="error" />
  </v-container>
</template>

<script>
import FileDrop from "@/components/FileDrop";
import WarningOverlay from "@/components/WarningOverlay";

export default {
  name: "UploadFiles",
  components: {
    FileDrop,
    WarningOverlay
  },
  data: () => ({
    showWarning: false
  }),
  watch: {
    error: function(err) {
      if (err !== undefined) {
        this.showWarning = true;
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    userGroup() {
      return this.$store.getters["auth/userGroup"];
    },
    error() {
      return this.$store.getters["uploads/error"];
    }
  },
  methods: {
    processUpload(files) {
      let formData = new FormData();

      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("files[]", file);
      }

      this.$store.dispatch("uploads/create", {
        files: formData,
        userGroup: this.userGroup
      });
    }
  }
};
</script>
