import http from "@/lib/Http";

export default {
  namespaced: true,
  state: {
    loading: false,
    files: [],
    error: undefined
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    addFile(state, payload) {
      state.files.push(payload);
    },
    removeFile(state, payload) {
      const idx = state.files.findIndex(f => f.name === payload);
      if (idx > -1) state.files.splice(idx, 1);
    },
    setError(state, payload) {
      state.error = payload;
    }
  },

  getters: {
    loading: state => state.loading,
    files: state => state.files,
    error: state => state.error
  },

  actions: {
    create({ commit }, params) {
      commit("setLoading", true);
      http
        .post(`/uploads?client=${params.userGroup}`, params.files, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          commit("setError", undefined);
          commit("setFiles", []);
        })
        .catch(error => {
          commit("setError", error.response.data.message);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    }
  }
};
